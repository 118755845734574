import React, { useState } from 'react';

import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Select from '@mui/material/Select';
import MuiToolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';

import Logout from './Logout';

const AppBar = ({ labels = {}, onProfileClick, onLogout, onSelect, modules, currentModulePath, Toolbar }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <MuiAppBar>
      <MuiToolbar>
        <Typography variant="body1" component="div" sx={{ ml: 0, mr: 2, fontSize: 20 }}>
          {labels.title || 'GridOS'}
        </Typography>
        <Typography variant="body1" component="div" sx={{ ml: 0, mr: 2, fontSize: 20 }}>
          {labels.product || 'Product'}
        </Typography>
        {modules.length > 1 ? (
          <Select
            id="nav-bar-select"
            variant="outlined"
            value={currentModulePath}
            onChange={onSelect}
            sx={{
              ml: 3,
              minWidth: 200,
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              color: 'text.labelwhite',
            }}
          >
            {modules.map(({ path, label }) => (
              <MenuItem key={path} value={path}>
                {label}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography variant="body1" sx={{ margin: 0, fontSize: '20px', fontWeight: 400 }}>
            {modules[0].label}
          </Typography>
        )}
        <Box sx={{ flexGrow: 3 }} />
        {Toolbar}
        <Tooltip title={labels.userButton}>
          <IconButton onClick={onProfileClick} sx={{ ml: 1 }} color="inherit">
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <Box sx={{ ml: 1, mr: 1, width: 0, borderLeft: '1px solid', borderColor: 'borders.moderate', height: 50 }} />
        <IconButton onClick={handleClick} color="inherit">
          <MoreVertIcon />
        </IconButton>
      </MuiToolbar>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Logout labels={labels.logout} logout={onLogout} />
      </Menu>
    </MuiAppBar>
  );
};

export default AppBar;
